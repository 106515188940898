import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import PageLoading from './components/PageLoading';
import AuthenticatedRoute from './components/_routes/AuthenticatedRoute';
import MeRoute from './components/_routes/MeRoute';
import TaskRoute from './components/_routes/TaskRoute';
import UnauthenticatedRoute from './components/_routes/UnauthenticatedRoute';

const AsyncDashboard = React.lazy(
  () => import(/* webpackChunkName: 'Dashboard' */ './domains/Dashboard'),
);
const AsyncCustomer = React.lazy(
  () => import(/* webpackChunkName: 'Customer' */ './domains/Customer'),
);
const AsyncHouse = React.lazy(() => import(/* webpackChunkName: 'House' */ './domains/House'));
const AsyncIntake = React.lazy(() => import(/* webpackChunkName: 'Intake' */ './domains/Intake'));
const AsyncInstallatron = React.lazy(
  () => import(/* webpackChunkName: 'Installatron' */ './domains/Installatron'),
);
const AsyncCalendar = React.lazy(
  () => import(/* webpackChunkName: 'Calendar' */ './domains/Calendar'),
);
const AsyncEmailer = React.lazy(
  () => import(/* webpackChunkName: 'Communications' */ './domains/Communications'),
);
const AsyncOrders = React.lazy(() => import(/* webpackChunkName: 'Orders' */ './domains/Orders'));
const AsyncAdvice = React.lazy(() => import(/* webpackChunkName: 'Advice' */ './domains/Advice'));
const AsyncObama = React.lazy(() => import(/* webpackChunkName: 'Obama' */ './domains/Obama'));
const AsyncProducts = React.lazy(
  () => import(/* webpackChunkName: 'Products' */ './domains/Products'),
);
const AsyncOrderItems = React.lazy(
  () => import(/* webpackChunkName: 'OrderItems' */ './domains/OrderItems'),
);
const AsyncPlanning = React.lazy(
  () => import(/* webpackChunkName: 'Product' */ './domains/Planning'),
);
const AsyncJobs = React.lazy(() => import(/* webpackChunkName: 'Jobs' */ './domains/Job'));
const AsyncInstaller = React.lazy(
  () => import(/* webpackChunkName: 'Installer' */ './domains/Installer'),
);
const AsyncInstallerCoverage = React.lazy(
  () => import(/* webpackChunkName: 'InstallerCoverage' */ './domains/InstallerCoverage'),
);
const AsyncOperatorCoverage = React.lazy(
  () => import(/* webpackChunkName: 'OperatorCoverage' */ './domains/OperatorCoverage'),
);
const AsyncPartnerAgent = React.lazy(
  () => import(/* webpackChunkName: 'PartnerAgent' */ './domains/PartnerAgent'),
);
const AsyncSupplier = React.lazy(
  () => import(/* webpackChunkName: 'Supplier' */ './domains/Supplier'),
);
const AsyncCoupons = React.lazy(
  () => import(/* webpackChunkName: 'Coupons' */ './domains/Coupons'),
);
const AsyncNotFound = React.lazy(
  () => import(/* webpackChunkName: 'NotFound' */ './domains/NotFound'),
);
const AsyncLogin = React.lazy(() => import(/* webpackChunkName: 'Login' */ './domains/Login'));

const AsyncInstallerAgentsDirectory = React.lazy(
  () => import(/* webpackChunkName: 'InstallerAgentAdmin' */ './domains/InstallerAgentAdmin'),
);
const AsyncOperatorDirectory = React.lazy(
  () => import(/* webpackChunkName: 'Operators' */ './domains/Operators'),
);
const AsyncOperator = React.lazy(
  () => import(/* webpackChunkName: 'PerOperator' */ './domains/Operators/PerOperator'),
);
const AsyncCompanies = React.lazy(
  () => import(/* webpackChunkName: 'Companies' */ './domains/Companies'),
);
const AsyncRating = React.lazy(() => import(/* webpackChunkName: 'Rating' */ './domains/Rating'));
const AsyncRateJob = React.lazy(
  () => import(/* webpackChunkName: 'Rating' */ './domains/Rating/RateJob'),
);
const AsyncMetricsBoards = React.lazy(
  () => import(/* webpackChunkName: 'MetricsBoards' */ './domains/DataAnalysis'),
);
const AsyncEnergyLabels = React.lazy(
  () => import(/* webpackChunkName: 'EnergyLabels' */ './domains/EnergyLabels'),
);
const AsyncQualityControl = React.lazy(
  () => import(/* webpackChunkName: 'QualityControl' */ './domains/QualityControl'),
);
const AsyncDataControl = React.lazy(
  () => import(/* webpackChunkName: 'KeyDates' */ './domains/DataControlPanel'),
);
const AsyncDossiersOverview = React.lazy(
  () => import(/* webpackChunkName: 'Dossiers' */ './domains/Dossiers'),
);
const AsyncJobSignoff = React.lazy(
  () => import(/* webpackChunkName: 'Signoff' */ './domains/Signoff'),
);
const AsyncTaskFlow = React.lazy(
  () => import(/* webpackChunkName: 'JobMutations' */ './domains/TaskFlow'),
);
const AsyncPartnerManagement = React.lazy(
  () => import(/* webpackChunkName: 'PartnerManagement' */ './domains/PartnerManagement'),
);
const AsyncPartnerCompany = React.lazy(
  () =>
    import(/* webpackChunkName: 'PartnerCompany' */ './domains/PartnerManagement/PartnerCompany'),
);
const AsyncPartnerOffice = React.lazy(
  () => import(/* webpackChunkName: 'PartnerOffice' */ './domains/PartnerManagement/PartnerOffice'),
);
const AsyncConfig = React.lazy(() => import(/* webpackChunkName: 'Config' */ './domains/Config'));
const AsyncBrands = React.lazy(() => import(/* webpackChunkName: 'Brands' */ './domains/Brands'));
const AsyncTickets = React.lazy(
  () => import(/* webpackChunkName: 'Tickets' */ './domains/Tickets'),
);
const AsyncTicketForm = React.lazy(
  () => import(/* webpackChunkName: 'TicketForm' */ './domains/TicketForm'),
);

export const Routes = () => {
  return (
    <Suspense fallback={<PageLoading isLoading />}>
      <Router>
        <Switch>
          <UnauthenticatedRoute path="/login" component={AsyncLogin} />
          <MeRoute exact path="/me" component={AsyncOperator} />
          <AuthenticatedRoute exact path="/" component={AsyncDashboard} />
          <AuthenticatedRoute path="/house/:houseId/customer" component={AsyncCustomer} />
          <AuthenticatedRoute path="/house/:houseId" exact component={AsyncHouse} />
          <AuthenticatedRoute path="/house/:houseId/calendar" component={AsyncCalendar} />
          <AuthenticatedRoute path="/house/:houseId/labels" component={AsyncEnergyLabels} />
          <AuthenticatedRoute path="/house/:houseId/email" component={AsyncEmailer} />
          <AuthenticatedRoute path="/house/:houseId/intake" component={AsyncIntake} />
          <AuthenticatedRoute path="/house/:houseId/installatron" component={AsyncInstallatron} />
          <AuthenticatedRoute path="/house/:houseId/orders" component={AsyncOrders} />
          <AuthenticatedRoute path="/house/:houseId/advice" component={AsyncAdvice} />
          <AuthenticatedRoute path="/house/:houseId/obama" component={AsyncObama} />
          <AuthenticatedRoute path="/house/:houseId/controle" component={AsyncQualityControl} />
          <AuthenticatedRoute path="/coupons" component={AsyncCoupons} />
          <AuthenticatedRoute exact path="/users/operators" component={AsyncOperatorDirectory} />
          <AuthenticatedRoute
            exact
            path="/users/installer"
            component={AsyncInstallerAgentsDirectory}
          />
          <AuthenticatedRoute path="/users/operators/:operator" component={AsyncOperator} />
          <AuthenticatedRoute path="/partners" component={AsyncCompanies} />
          <AuthenticatedRoute path="/brands" component={AsyncBrands} />
          <AuthenticatedRoute path="/partner-management" component={AsyncPartnerManagement} />
          <AuthenticatedRoute path="/partner-company/:companyId" component={AsyncPartnerCompany} />
          <AuthenticatedRoute path="/partner-office/:officeId" component={AsyncPartnerOffice} />
          <AuthenticatedRoute path="/planning" exact component={AsyncPlanning} />
          <AuthenticatedRoute path="/planning/:quoteId" component={AsyncPlanning} />
          <AuthenticatedRoute path="/opdracht/:quoteId/:jobType" component={AsyncJobs} />
          <AuthenticatedRoute path="/products/:solution/:category" component={AsyncProducts} />
          <AuthenticatedRoute path="/products/:solution" component={AsyncProducts} />
          <AuthenticatedRoute path="/products" component={AsyncProducts} />
          <AuthenticatedRoute path="/pakketten" component={AsyncOrderItems} />
          <AuthenticatedRoute path="/ticket" component={AsyncTicketForm} />
          <AuthenticatedRoute path="/installer/:installerId" component={AsyncInstaller} />
          <AuthenticatedRoute path="/installer-coverage" component={AsyncInstallerCoverage} />
          <AuthenticatedRoute path="/operator-coverage" component={AsyncOperatorCoverage} />
          <AuthenticatedRoute path="/partner-agent/:partnerAgentId" component={AsyncPartnerAgent} />
          <AuthenticatedRoute path="/opleverdocument/:jobId" component={AsyncJobSignoff} />
          <AuthenticatedRoute path="/schouw-resultaten/:jobId" component={AsyncJobSignoff} />

          <AuthenticatedRoute path="/supplier/:supplierId" component={AsyncSupplier} />
          <AuthenticatedRoute exact path="/review" component={AsyncRating} />
          <AuthenticatedRoute path="/review/:jobId" component={AsyncRateJob} />
          <AuthenticatedRoute exact path="/data" component={AsyncMetricsBoards} />
          <AuthenticatedRoute path="/data/:page" component={AsyncMetricsBoards} />
          <AuthenticatedRoute
            path="/data-control/:houseId/:page/"
            component={AsyncDataControl}
            exact
          />
          <AuthenticatedRoute
            path="/data-control/:houseId/:page/:solution"
            component={AsyncDataControl}
          />
          <AuthenticatedRoute path="/dossiers" component={AsyncDossiersOverview} />
          <AuthenticatedRoute path="/config" component={AsyncConfig} />
          <AuthenticatedRoute path="/tickets" component={AsyncTickets} />
          <TaskRoute path="/taak/:taskSlug/:entityId/:taskParam?" component={AsyncTaskFlow} />
          <AuthenticatedRoute component={AsyncNotFound} />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default Routes;
